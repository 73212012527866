import { gsap } from "gsap";
import 'slick-carousel';
import SimpleBar from 'simplebar';


//トップページ　キービジュアルスライダー
export function idxMainVisualFunc() {
  let indexMainVisual = $("#indexTop").length;
  let openingTxt1 = $("#openingTxt1")
  let openingTxt2 = $("#openingTxt2")
  let openingTxt3 = $("#openingTxt3")
  if(indexMainVisual){
    const humanAnimation = document.getElementById('humanAnimation');
  const humanAnimationStop = document.getElementById('humanAnimationStop');
  // 1番目に関する定数
  const scene01 = document.querySelector("#scene01");
  const scene01Train = document.querySelector("#scene01 .trainAnime");
  const scene01TrainTouch = document.querySelector("#scene01 #trainAnimeTouch")
  const scene01Discovery = document.querySelector("#trainDiscovery");
  const trainLucky = document.querySelector("#trainLucky");
  const humanAnimationTouch01 = document.querySelector("#humanAnimationTouch01");
  const humanAnimationTouch01_01 = document.querySelector("#humanAnimationTouch01_01");
  const humanAnimationTouch01_02 = document.querySelector("#humanAnimationTouch01_02");
  const humanAnimationTouch01_03 = document.querySelector("#humanAnimationTouch01_03");
    // 2番目に関する定数
  const scene02 = document.querySelector("#scene02");
  const scene02Terminal = document.querySelector("#scene02 .terminalAnime");
  const scene02TerminalTouch = document.querySelector("#scene02 #terminalAnimeTouch")
  const scene02Discovery = document.querySelector("#terminalDiscovery");
  const terminalLucky = document.querySelector("#terminalLucky");
  const humanAnimationTouch02 = document.querySelector("#humanAnimationTouch02");
  const humanAnimationTouch02_01 = document.querySelector("#humanAnimationTouch02_01");
  const humanAnimationTouch02_02 = document.querySelector("#humanAnimationTouch02_02");
  const humanAnimationTouch02_03 = document.querySelector("#humanAnimationTouch02_03");
  // 3番目に関する定数
  const scene03 = document.querySelector("#scene03");
  const scene03Bus = document.querySelector("#scene03 .busAnime")
  const scene03BusTouch = document.querySelector("#scene03 #busAnimeTouch")
  const scene03Discovery = document.querySelector("#busDiscovery")
  const busLucky = document.querySelector("#busLucky")
  const humanAnimationTouch03 = document.querySelector("#humanAnimationTouch03")
  const humanAnimationTouch03_01 = document.querySelector("#humanAnimationTouch03_01")
  const humanAnimationTouch03_02 = document.querySelector("#humanAnimationTouch03_02")
  const humanAnimationTouch03_03 = document.querySelector("#humanAnimationTouch03_03")
  // 4番目に関する定数
  const scene04 = document.querySelector("#scene04");
  const scene04Event = document.querySelector("#scene04 .eventAnime");
  const scene04EventTouch = document.querySelector("#scene04 #eventAnimeTouch")
  const scene04Discovery = document.querySelector("#eventDiscovery");
  const eventLucky = document.querySelector("#eventLucky");
  const humanAnimationTouch04 = document.querySelector("#humanAnimationTouch04");
  const humanAnimationTouch04_01 = document.querySelector("#humanAnimationTouch04_01");
  const humanAnimationTouch04_02 = document.querySelector("#humanAnimationTouch04_02");
  const humanAnimationTouch04_03 = document.querySelector("#humanAnimationTouch04_03");
  // 5番目に関する定数
  const scene05 = document.querySelector("#scene05");
  const scene05Store = document.querySelector("#scene05 .storeAnime")
  const scene05StoreTouch = document.querySelector("#scene05 #storeAnimeTouch")
  const scene05Discovery = document.querySelector("#storeDiscovery")
  const storeLucky = document.querySelector("#storeLucky")
  const humanAnimationTouch05 = document.querySelector("#humanAnimationTouch05")
  const humanAnimationTouch05_01 = document.querySelector("#humanAnimationTouch05_01")
  const humanAnimationTouch05_02 = document.querySelector("#humanAnimationTouch05_02")
  const humanAnimationTouch05_03 = document.querySelector("#humanAnimationTouch05_03")
    // 6番目に関する定数
  const scene06 = document.querySelector("#scene06");
  const scene06Food = document.querySelector("#scene06 .foodAnime");
  const scene06FoodTouch = document.querySelector("#scene06 #foodAnimeTouch")
  const scene06Discovery = document.querySelector("#foodDiscovery");
  const foodLucky = document.querySelector("#foodLucky");
  const humanAnimationTouch06 = document.querySelector("#humanAnimationTouch06");
  const humanAnimationTouch06_01 = document.querySelector("#humanAnimationTouch06_01");
  const humanAnimationTouch06_02 = document.querySelector("#humanAnimationTouch06_02");
  const humanAnimationTouch06_03 = document.querySelector("#humanAnimationTouch06_03");
  // カレント変更ボタンの定数
  const scene01Button = document.querySelector("#scene01Button");
  const scene02Button = document.querySelector("#scene02Button");
  const scene03Button = document.querySelector("#scene03Button");
  const scene04Button = document.querySelector("#scene04Button");
  const scene05Button = document.querySelector("#scene05Button");
  const scene06Button = document.querySelector("#scene06Button");

  let currentScene = 1;

  const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
  wait(1000)
    .then(() => {
      openingTxt1.addClass("isShow");
      return wait(2000)
    }).then(() => {
      openingTxt2.addClass("isShow");
      return wait(3000)
    }).then(() => {
      openingTxt3.addClass("isShow");
      return wait(3000)
    }).then(() => {
      //人間アニメーションの開始
      animateHuman();
      humanAnimation.classList.add("isShow")
      return wait(1000)
    }).then(() => {
      sliderBtnFunc();
      linkBtnFunc();
      scene01AnimeFunc();
    })

    function linkBtnFunc(){
      const linkBtn = document.querySelector("#linkBtn");
      linkBtn.classList.add("isShow");
    }

    function sliderBtnFunc(){
      const sliderWrap = document.querySelector(".sliderWrap");
      sliderWrap.classList.add("isShow");
    }

    //人間が歩くアニメーション
    const images = humanAnimation.getElementsByTagName('img');
    let currentImageIndex = 0;
    let animationTimeout;
    function animateHuman() {
      images[currentImageIndex].style.display = 'none';
      currentImageIndex = (currentImageIndex + 1) % images.length;
      images[currentImageIndex].style.display = 'block';
      animationTimeout = setTimeout(animateHuman, 150);
    }
    // 人間アニメーション停止
    function stopAnimation() {
      clearTimeout(animationTimeout);
    }
    //人間アニメーション再開
    function resumeAnimation() {
      animateHuman();
    }

    //タッチ後に画像を非表示にする
    function hideTouchImage(touchNumber) {
      const touchElement = document.querySelector(`#humanAnimationTouch${touchNumber}`);
      if (touchElement) {
        touchElement.style.display = "none";
        humanAnimation.style.display = "block";
        resumeAnimation();
      }
    }
    
    //カレント移動の際の人間アニメーションリセット
    function startAnimation() {
      stopAnimation();
      animateHuman();
    }

    function btnCurrentFunc() {
      const sceneButtons = Array.from(document.querySelectorAll(".sliderButton"));
      const currentBtn = sceneButtons[currentScene - 1];
      // カレントボタンを無効化
      currentBtn.disabled = true;
      currentBtn.classList.add("currentScene")
      // カレントではないボタンを無効化
      const otherButtons = sceneButtons.filter((button) => button !== currentBtn);
      otherButtons.forEach((button) => {
        button.disabled = true;
        button.classList.remove("currentScene");
      });
      //カレントではないボタンを2秒後に有効化
      setTimeout(() => {
        otherButtons.forEach((button) => {
          button.disabled = false;
        });
      }, 2000);
    }

    //各シーンのタイトルアニメーション
    function mainLeadAnimeFunc(className) {
      var mainLead = $(className);
      mainLead.each(function () {
        var speed = 100;
        var txtNum = 0;
        var animationFlag = false;
        var spans = mainLead.find('span');
        
        if (!animationFlag && txtNum < spans.length) {
          animationFlag = true;
          var interval = setInterval(function () {
            spans.eq(txtNum).addClass("animeLead");
            txtNum++;
            
            if (txtNum >= spans.length) {
              clearInterval(interval);
            }
          }, speed);
        }
      });
    }
    

    //各シーンのタイトルアニメーションのリセット
    function resetMainLeadAnimeFunc(className) {
      var mainLead = $(className);
      mainLead.each(function () {
        $(this).find("span").removeClass("animeLead");
        $(this).removeClass("moveTxt");
      });
    }

    //各シーンの吹き出しアニメーション
    function mainBalloonAnimeFunc(className) {
      var mainBallon = $(className);
      mainBallon.addClass("animeBalloon");
    }
    
    //各シーンのタイトルアニメーションのリセット
    function resetMainBalloonAnimeFunc(className) {
      var mainBallon = $(className);
      mainBallon.removeClass("animeBalloon");
    }

    //1シーン目のアニメーションリセット
    function resetscene01AnimeFunc() {
      scene01.style.cssText = "display: none; opacity: 1;";
      scene01Train.style.transform = "translateX(-100%)";
      scene01TrainTouch.style.display = "none";
      trainLucky.style.opacity = 0;
      humanAnimationTouch01.style.display = "none";
      humanAnimationTouch01_01.style.display = "block";
      humanAnimationTouch01_02.style.display = "none";
      humanAnimationTouch01_03.style.display = "none";
      scene01Discovery.style.opacity = 0;
    
      // アニメーションの途中であれば停止する
      gsap.killTweensOf([
        scene01Train,
        humanAnimationTouch01,
        scene01Discovery,
        trainLucky,
        humanAnimationTouch01_01,
        humanAnimationTouch01_02,
        humanAnimationTouch01_03,
        scene01,
        scene01TrainTouch,
      ]);

      //他の要素を初期状態に戻すための処理
      resetMainLeadAnimeFunc(".scene01Ttl");
      resetMainBalloonAnimeFunc("#trainLucky");
      startAnimation();
    }

    //2シーン目のアニメーションリセット
    function resetscene02AnimeFunc() {
      scene02.style.cssText = "display: none; opacity: 1;";
      scene02Terminal.style.transform = "translateX(-100%)";
      scene02TerminalTouch.style.display = "none";
      terminalLucky.style.opacity = 0;
      humanAnimationTouch02.style.display = "none";
      humanAnimationTouch02_01.style.display = "block";
      humanAnimationTouch02_02.style.display = "none";
      humanAnimationTouch02_03.style.display = "none";
      scene02Discovery.style.opacity = 0;
    
      // アニメーションの途中であれば停止する
      gsap.killTweensOf([
        scene02Terminal,
        humanAnimationTouch02,
        scene02Discovery,
        terminalLucky,
        humanAnimationTouch02_01,
        humanAnimationTouch02_02,
        humanAnimationTouch02_03,
        scene02,
        scene02TerminalTouch,
      ]);
    
      //他の要素を初期状態に戻すための処理
      resetMainLeadAnimeFunc(".scene02Ttl");
      resetMainBalloonAnimeFunc("#terminalLucky");
      startAnimation();
    }

    //3シーン目のアニメーションリセット
    function resetscene03AnimeFunc() {
      scene03.style.cssText = "display: none; opacity: 1;";
      scene03Bus.style.transform = "translateX(-100%)";
      scene03BusTouch.style.display = "none";
      busLucky.style.opacity = 0;
      humanAnimationTouch03.style.display = "none";
      humanAnimationTouch03_01.style.display = "block";
      humanAnimationTouch03_02.style.display = "none";
      humanAnimationTouch03_03.style.display = "none";
      scene03Discovery.style.opacity = 0;
    
      // アニメーションの途中であれば停止する
      gsap.killTweensOf([
        scene03Bus,
        humanAnimationTouch03,
        scene03Discovery,
        busLucky,
        humanAnimationTouch03_01,
        humanAnimationTouch03_02,
        humanAnimationTouch03_03,
        scene03,
        scene03BusTouch,
      ]);
    
      //他の要素を初期状態に戻すための処理
      resetMainLeadAnimeFunc(".scene03Ttl");
      resetMainBalloonAnimeFunc("#busLucky");
      startAnimation();
    }

    //4シーン目のアニメーションリセット
    function resetscene04AnimeFunc() {
      scene04.style.cssText = "display: none; opacity: 1;";
      scene04Event.style.transform = "translateX(-100%)";
      scene04EventTouch.style.display = "none";
      eventLucky.style.opacity = 0;
      humanAnimationTouch04.style.display = "none";
      humanAnimationTouch04_01.style.display = "block";
      humanAnimationTouch04_02.style.display = "none";
      humanAnimationTouch04_03.style.display = "none";
      scene04Discovery.style.opacity = 0;
    
      gsap.killTweensOf([
        scene04Event,
        humanAnimationTouch04,
        scene04Discovery,
        eventLucky,
        humanAnimationTouch04_01,
        humanAnimationTouch04_02,
        humanAnimationTouch04_03,
        scene04,
        scene04EventTouch,
      ]);
    
      // 必要な場合、他の要素の初期状態に戻すための処理を追加
      resetMainLeadAnimeFunc(".scene04Ttl");
      resetMainBalloonAnimeFunc("#eventLucky");
      startAnimation();
    }

    //5シーン目のアニメーションリセット
    function resetscene05AnimeFunc() {
      scene05.style.cssText = "display: none; opacity: 1;";
      scene05Store.style.transform = "translateX(-100%)";
      scene05StoreTouch.style.display = "none";
      storeLucky.style.opacity = 0;
      humanAnimationTouch05.style.display = "none";
      humanAnimationTouch05_01.style.display = "block";
      humanAnimationTouch05_02.style.display = "none";
      humanAnimationTouch05_03.style.display = "none";
      scene05Discovery.style.opacity = 0;
    
      // アニメーションの途中であれば停止する
      gsap.killTweensOf([
        scene05Store,
        humanAnimationTouch05,
        scene05Discovery,
        storeLucky,
        humanAnimationTouch05_01,
        humanAnimationTouch05_02,
        humanAnimationTouch05_03,
        scene05,
        scene05StoreTouch,
      ]);
    
      //他の要素を初期状態に戻すための処理
      resetMainLeadAnimeFunc(".scene05Ttl");
      resetMainBalloonAnimeFunc("#storeLucky");
      startAnimation();
    }

    //6シーン目のアニメーションリセット
    function resetscene06AnimeFunc() {
      scene06.style.cssText = "display: none; opacity: 1;";
      scene06Food.style.transform = "translateX(-100%)";
      scene06FoodTouch.style.display = "none";
      foodLucky.style.opacity = 0;
      humanAnimationTouch06.style.display = "none";
      humanAnimationTouch06_01.style.display = "block";
      humanAnimationTouch06_02.style.display = "none";
      humanAnimationTouch06_03.style.display = "none";
      scene06Discovery.style.opacity = 0;
    
      gsap.killTweensOf([
        scene06Food,
        humanAnimationTouch06,
        scene06Discovery,
        foodLucky,
        humanAnimationTouch06_01,
        humanAnimationTouch06_02,
        humanAnimationTouch06_03,
        scene06,
        scene06FoodTouch,
      ]);
    
      //他の要素を初期状態に戻すための処理
      resetMainLeadAnimeFunc(".scene06Ttl");
      resetMainBalloonAnimeFunc("#foodLucky");
      startAnimation();
    }

    //1番目のアニメーション
    function scene01AnimeFunc(){
      currentScene = 1;
      btnCurrentFunc();
      const scene01Anime = gsap.timeline();
      scene01.style.display = "block";
      scene01Anime.fromTo(scene01Train , 
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene01Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" }, 
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene01Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene01Anime.fromTo(
                scene01Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene01Anime.fromTo(
                      scene01Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene01Anime.fromTo(
                            scene01Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene01Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch01 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch01_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch01_02 ,{display: "none"}, {display: "block"})
                                .fromTo(trainLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#trainLucky")
                                }})
                                .fromTo(humanAnimationTouch01_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene01TrainTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene01, { display:"block" }, { opacity:"0", display: "none", duration: 1, onComplete: function() {
                                  hideTouchImage(3);
                                  scene02AnimeFunc();
                                  resetscene01AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }
    //2番目のアニメーション
    function scene02AnimeFunc(){
      currentScene = 2;
      btnCurrentFunc();
      const scene02Anime = gsap.timeline();
      scene02.style.display = "block";
      scene02Anime.fromTo(scene02Terminal , 
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene02Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" }, 
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene02Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene02Anime.fromTo(
                scene02Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene02Anime.fromTo(
                      scene02Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene02Anime.fromTo(
                            scene02Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene02Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch02 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch02_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch02_02 ,{display: "none"}, {display: "block"})
                                .fromTo(terminalLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#terminalLucky")
                                }})
                                .fromTo(humanAnimationTouch02_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene02TerminalTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene02, { display:"block" }, { opacity:"0", display: "none", duration: 1, onComplete: function() {
                                  hideTouchImage(4);
                                  scene03AnimeFunc();
                                  resetscene02AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }
    // 3シーン目のアニメーション
    function scene03AnimeFunc(){
      currentScene = 3;
      btnCurrentFunc();
      const scene03Anime = gsap.timeline();
      scene03.style.display = "block";
      scene03Anime.fromTo(scene03Bus , 
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene03Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" }, 
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene03Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene03Anime.fromTo(
                scene03Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene03Anime.fromTo(
                      scene03Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene03Anime.fromTo(
                            scene03Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene03Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch03 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch03_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch03_02 ,{display: "none"}, {display: "block"})
                                .fromTo(busLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#busLucky")
                                }})
                                .fromTo(humanAnimationTouch03_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene03BusTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene03, { display:"block" }, { opacity:"0",display: "none", duration: 1, onComplete: function() {
                                  hideTouchImage(1);
                                  scene04AnimeFunc();
                                  resetscene03AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }
    // 4番目のアニメーション
    function scene04AnimeFunc(){
      currentScene = 4;
      btnCurrentFunc();
      const scene04Anime = gsap.timeline();
      scene04.style.display = "block";
      scene04Anime.fromTo(scene04Event ,
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene04Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" },
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene04Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene04Anime.fromTo(
                scene04Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene04Anime.fromTo(
                      scene04Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene04Anime.fromTo(
                            scene04Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene04Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch04 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch04_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch04_02 ,{display: "none"}, {display: "block"})
                                .fromTo(eventLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#eventLucky")
                                }})
                                .fromTo(humanAnimationTouch04_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene04EventTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene04, { display: "block" }, { opacity:"0", display: "none", duration: 1, onComplete: function() {
                                  // アニメーションが終わったらscene06AnimeFunc()を開始
                                  hideTouchImage(6);
                                  scene05AnimeFunc();
                                  resetscene04AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }

    //5番目のアニメーション
    function scene05AnimeFunc(){
      currentScene = 5;
      btnCurrentFunc();
      const scene05Anime = gsap.timeline();
      scene05.style.display = "block";
      scene05Anime.fromTo(scene05Store , 
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene05Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" }, 
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene05Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene05Anime.fromTo(
                scene05Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene05Anime.fromTo(
                      scene05Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene05Anime.fromTo(
                            scene05Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene05Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch05 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch05_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch05_02 ,{display: "none"}, {display: "block"})
                                .fromTo(storeLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#storeLucky")
                                }})
                                .fromTo(humanAnimationTouch05_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene05StoreTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene05, { display:"block" }, { opacity:"0", display: "none", duration: 1, onComplete: function() {
                                  hideTouchImage(2);
                                  scene06AnimeFunc();
                                  resetscene05AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }
    // 6番目のアニメーション
    function scene06AnimeFunc(){
      currentScene = 6;
      btnCurrentFunc();
      const scene06Anime = gsap.timeline();
      scene06.style.display = "block";
      scene06Anime.fromTo(scene06Food , 
        {x:"-100%"},
        {x:"0%", duration: 1, onStart: function(){
          mainLeadAnimeFunc(".scene06Ttl");
        }})
      .fromTo(humanAnimation,
        { display: "block" },
        { display: "none", onComplete: stopAnimation })
      .fromTo(humanAnimationStop,
        {display:"none"},
        {display:"block"})
      .fromTo(
        scene06Discovery,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: .2,
            onComplete: function() {
              scene06Anime.fromTo(
                scene06Discovery,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: .2,
                  onComplete: function() {
                    scene06Anime.fromTo(
                      scene06Discovery,
                      {
                        opacity: 0,
                      },
                      {
                        opacity: 1,
                        duration: .2,
                        onComplete: function() {
                          scene06Anime.fromTo(
                            scene06Discovery,
                            {
                              opacity: 1,
                            },
                            {
                              opacity: 0,
                              duration: .2,
                              onComplete: function() {
                                scene06Anime.fromTo(humanAnimationStop,{display:"block"},{display:"none"})
                                .fromTo(humanAnimationTouch06 ,{display: "none"},{display: "block"})
                                .fromTo(humanAnimationTouch06_01 ,{display:"block"}, {display: "none"})
                                .fromTo(humanAnimationTouch06_02 ,{display: "none"}, {display: "block"})
                                .fromTo(foodLucky, {opacity:0},{opacity:1, duration: 1,onStart:function(){
                                  mainBalloonAnimeFunc("#foodLucky")
                                }})
                                .fromTo(humanAnimationTouch06_03 ,{display: "none"}, {display: "block",duration: 1},"-=1")
                                .fromTo(scene06FoodTouch, {display:"none"},{display:"block", duration: 1},"-=1")
                                .fromTo(scene06, { display: "block" }, { opacity:"0", display: "none", duration: 1, onComplete: function() {
                                  // アニメーションが終わったらscene06AnimeFunc()を開始
                                  hideTouchImage(5);
                                  scene01AnimeFunc();
                                  resetscene06AnimeFunc();
                                } });
                              },
                            }
                          );
                        },
                      }
                    );
                  },
                }
              );
            },
          }
        )
    }

    // シーンの切り替え関数
    function changeScene(sceneIndex) {
      // 現在のシーンと同じシーンなら何もしない
      if (sceneIndex === currentScene) {
        return;
      }

      // カレントシーンの変更
      currentScene = sceneIndex;

      // シーンごとの表示切り替えとアニメーションの開始
      const scenes = [scene01, scene02, scene03, scene04, scene05, scene06];
      const animeFuncs = [scene01AnimeFunc, scene02AnimeFunc, scene03AnimeFunc, scene04AnimeFunc, scene05AnimeFunc, scene06AnimeFunc];

      for (let i = 0; i < scenes.length; i++) {
        scenes[i].style.display = i === (sceneIndex - 1) ? "block" : "none";
      }

      humanAnimationStop.style.display = "none";
      humanAnimation.style.display = "block";

      animeFuncs[sceneIndex - 1]();
    }

    // カレントシーンの初期設定
    changeScene(currentScene);
    const sceneButtons = [scene01Button, scene02Button, scene03Button, scene04Button, scene05Button, scene06Button];
    for (let i = 0; i < sceneButtons.length; i++) {
      sceneButtons[i].addEventListener("click", function() {
        resetAllSceneAnimeFuncs(i + 1);
        changeScene(i + 1);
      });
    }

    function resetAllSceneAnimeFuncs(excludeIndex) {
      const animeFuncs = [resetscene01AnimeFunc, resetscene02AnimeFunc, resetscene03AnimeFunc, resetscene04AnimeFunc, resetscene05AnimeFunc, resetscene06AnimeFunc];
      for (let i = 0; i < animeFuncs.length; i++) {
        if (i !== (excludeIndex - 1)) {
          animeFuncs[i]();
        }
      }
    }
  }
}

export function idxFunc() {
  let indexTop = $("#indexTop").length;
  if(indexTop){
    locationSlideFunc();
    indexHeaderScrFunc();
    indexHeaderFirstHide();
    linkBtnInFunc();
    $(window).on('scroll', function () {
      indexHeaderScrFunc();
      scrollFadeFunc();
      linkBtnInFunc();
    });

    let resizecheck = $('#responsibleCheck').width();
	  
		window.addEventListener('resize', () => {
		  resizecheck = $('#responsibleCheck').width();
	  
		  //アコーディオン初期化
			indexHeaderScrFunc();
      indexHeaderFirstHide();
      scrollFadeFunc();
      linkBtnInFunc();
		}, false);

    function linkBtnInFunc(){
      let scrTopFlg = 0;
      let menuOpenFlg = 0;
      var infoLayoutElement = $('.infoLayout');
      var windowHeight = window.innerHeight;
      var elementHeight = infoLayoutElement.offset().top;
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < elementHeight - (windowHeight / 2) ) {
          scrTopFlg = 1;
          $("#linkBtn").addClass('scrollHide');
        } else {
          scrTopFlg = 0;
          $("#linkBtn").removeClass('scrollHide');
        }
      }
    }

    function indexHeaderScrFunc(){
      let mainVisualHeight = $(".indexMainVisual").innerHeight();
      let scrTopFlg = 0;
      let menuOpenFlg = 0;
      let header = $("#header");
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < mainVisualHeight) {
          scrTopFlg = 1;
          header.addClass('changeColor');
        } else {
          scrTopFlg = 0;
          header.removeClass('changeColor').removeClass("hideHeader");
        }
      }

    }

    function indexHeaderFirstHide(){
      let mainVisualHeight = $(".indexMainVisual").innerHeight();
      let scrTopFlg = 0;
      let menuOpenFlg = 0;
      let header = $("#header");
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < mainVisualHeight) {
          scrTopFlg = 1;
          header.addClass('hideHeader');
        } else {
          scrTopFlg = 0;
          header.removeClass('hideHeader');
        }
      }
    }

    function scrollFadeFunc(){
      let mainVisualHeight = $(".indexMainVisual").innerHeight();
      let scrTopFlg = 0;
      let menuOpenFlg = 0;
      let header = $("#header")
      let scrTop = $(window).scrollTop();
      if (menuOpenFlg == 0) {
        if (scrTop < mainVisualHeight) {
          scrTopFlg = 1;
          let scrollTimeout;
          header.removeClass("hideHeader")
          clearTimeout(scrollTimeout);
          scrollTimeout = setTimeout(function () {
            header.addClass("hideHeader")
          }, 1000);
        } else {
          scrTopFlg = 0;
          header.removeClass("hideHeader")
        }
      }
    }
    
    function locationSlideFunc(){
      let slider = $(".locationSlide")
      slider.slick({
        slidesToShow: 4,
        infinite:true,
        autoplaySpeed:0,
        autoplay:true,
        speed: 5000,
        cssEase: "linear",
        arrows: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });
  
      slider.on('touchmove', function(event, slick, currentSlide, nextSlide){
        slider.slick('slickPlay');
      });
    }

    function playVideos(videoWrappers) {
			const startPosition = window.pageYOffset + window.innerHeight;
			for(let i = 0; i < videoWrappers.length; i++) {
				const videoPosition = videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
				if(startPosition > videoPosition) {
					const video = videoWrappers[i].getElementsByTagName('video');
					video[0].play();
				}
			}
		  }
		  const videoWrappers = document.getElementsByClassName('videoControl');
		  if(videoWrappers.length) {
			  playVideos(videoWrappers);
			  window.addEventListener('scroll', function() {
				  playVideos(videoWrappers);
			  }, false);
		}


  }


}